const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "编号",
                prop: "BH"
            }, {
                label: "中文名",
                prop: "SZ_ZWM"
            }, {
                label: "别名",
                prop: "SZ_BM"
            }, {
                label: "拉丁名",
                prop: "SZ_LDM"
            }, {
                label: "科名",
                prop: "SZ_KM"
            }, {
                label: "属名",
                prop: "SZ_SM"
            }, {
                label: "树龄(年)",
                prop: "SMXZ_SL"
            }, {
                label: "树高(m)",
                prop: "SMXZ_SG"
            }, {
                label: "胸径(cm)",
                prop: "SMXZ_XJ"
            }, {
                label: "平均冠幅(m)",
                prop: "SMXZ_PJGF"
            }, {
                label: "东西冠幅(m)",
                prop: "SMXZ_DXGF"
            }, {
                label: "南北冠幅(m)",
                prop: "SMXZ_NBGF"
            }, {
                label: "生长势",
                prop: "SZS"
            }, {
                label: "权属",
                prop: "QS"
            },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;